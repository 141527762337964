const routes = [
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/dashboard",
    "name": "Dashboard",
    "parentPage": "",
    "icon":['fas', 'columns'],
    "meta": {
      "layout": {
        "name": "AppLayout_12_4_4_4_12",
        "slug": "AppLayout_12_4_4_4_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "myCryptos",
              "slug": "myCryptos",
              "config": []
            }
          ],
          "column2": [
            {
              "_id": "",
              "name": "trending",
              "slug": "trending"
            }
          ],
          "column3": [
            {
              "_id": "",
              "name": "cardanoPairs",
              "slug": "cardanoPairs"
            }
          ],
          "column4": [
            {
              "_id": "",
              "name": "hotPairs",
              "slug": "hotPairs",
            }
          ],
          "column5": [
            {
              "_id": "",
              "name": "mainCryptosList",
              "slug": "mainCryptosList",
              "config": []
            }
          ],
        }
      },
      "title": "Dashboard",
      "description": "",
      "keywords": "",
      "requireAuth": true,
      "role": "",
      "mainMenu": true,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/swap",
    "name": "Swap(beta)",
    "parentPage": "",
    "icon":['fas', 'exchange-alt'],
    "meta": {
      "layout": {
        "name": "AppLayout_4_4_4",
        "slug": "AppLayout_4_4_4",
        "content": {
          "column1": [],
          "column2": [
            {
              "_id": "",
              "name": "swap",
              "slug": "swap",
              "config": []
            }
          ],
          "column3": []
        }
      },
      "title": "Swap",
      "description": "",
      "keywords": "",
      "requireAuth": true,
      "role": "",
      "mainMenu": true,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/packs",
    "name": "Packs",
    "parentPage": "",
    "icon":['fas', 'cube'],
    "meta": {
      "layout": {
        "name": "AppLayout_12",
        "slug": "AppLayout_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "packs",
              "slug": "packs",
              "config": []
            }
          ]
        }
      },
      "title": "Packs",
      "description": "",
      "keywords": "",
      "requireAuth": true,
      "role": "",
      "mainMenu": true,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/crypto/:chain/:address",
    "name": "crypto-detail",
    "parentPage": "",
    "icon":"",
    "meta": {
      "layout": {
        "name": "AppLayout_4_8",
        "slug": "AppLayout_4_8",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "tokenDetail",
              "slug": "tokenDetail",
              "config": []
            }
          ],
          "column2": [  
            {
              "_id": "",
              "name": "tradingViewCandles",
              "slug": "tradingViewCandles",
              "config": []
            }
          ]
        }
      },
      "title": "Crypto Detail",
      "description": "",
      "keywords": "",
      "requireAuth": true,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/login",
    "name": "login",
    "parentPage": "",
    "icon":['fas', 'cube'],
    "meta": {
      "layout": {
        "name": "AppLayout_4_4_4",
        "slug": "AppLayout_4_4_4",
        "content": {
          "column1": [],
          "column2": [
            {
              "_id": "",
              "name": "login",
              "slug": "login",
              "config": []
            }
          ],
          "column3": [],
        }
      },
      "title": "Login",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": true,
      "hideHeader": true
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/signup",
    "name": "SignUp",
    "parentPage": "",
    "icon":['fas', 'cube'],
    "meta": {
      "layout": {
        "name": "AppLayout_4_4_4",
        "slug": "AppLayout_4_4_4",
        "content": {
          "column1": [],
          "column2": [
            {
              "_id": "",
              "name": "signUp",
              "slug": "signUp",
              "config": []
            }
          ],
          "column3": [],
        }
      },
      "title": "Sign Up",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/forgot-password",
    "name": "ForgotPassword",
    "parentPage": "",
    "icon":['fas', 'cube'],
    "meta": {
      "layout": {
        "name": "AppLayout_4_4_4",
        "slug": "AppLayout_4_4_4",
        "content": {
          "column1": [],
          "column2": [
            {
              "_id": "",
              "name": "forgotPassword",
              "slug": "forgotPassword",
              "config": []
            }
          ],
          "column3": [],
        }
      },
      "title": "Forgot Password",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/",
    "name": "Home",
    "parentPage": "",
    "icon":['fas', 'exchange-alt'],
    "meta": {
      "layout": {
        "name": "AppLayout_12",
        "slug": "AppLayout_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "highlightedSideImage",
              "slug": "highlightedSideImage",   
            },
            {
              "_id": "",
              "name": "highlightedInfo",
              "slug": "highlightedInfo",   
            },
            {
              "_id": "",
              "name": "faqs",
              "slug": "faqs",   
            }
          ]
        }
      },
      "title": "Home",
      "description": "DXboard is the best way to manage your cryptos. Do you have your cryptocurrencies distributed among multiple exchanges, wallets, etc...? Don't you know what your global balance is? Dxboard gives you the solution, manage your crypto assets without risk, search and add your cryptos and know their price and balance in real time with no deposits",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": true
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/blog/:slug",
    "name": "BlogDetail",
    "parentPage": "",
    "icon":['fas', 'exchange-alt'],
    "meta": {
      "layout": {
        "name": "AppLayout_12",
        "slug": "AppLayout_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "blogDetail",
              "slug": "blogDetail",   
            }
          ]
        }
      },
      "title": "Blog Detail",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": true
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/blog",
    "name": "Blog",
    "parentPage": "",
    "icon":['fas', 'exchange-alt'],
    "meta": {
      "layout": {
        "name": "AppLayout_12",
        "slug": "AppLayout_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "blogList",
              "slug": "blogList",   
            }
          ]
        }
      },
      "title": "Blog",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": true
    }
  },
  { "path": "/401",
    "name": "page-unauthorized",
    "redirect": "/login",
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/403",
    "name": "page-forbidden",
    "parentPage": "",
    "icon":['fas', 'columns'],
    "meta": {
      "layout": {
        "name": "AppLayout_12",
        "slug": "AppLayout_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "PageForbidden403",
              "slug": "PageForbidden403",
              "config": []
            }
          ]
        }
      },
      "title": "Page Forbidden",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": false
    }
  },
  {
    "component": () => import("../layouts/AppLayout.vue"),
    "path": "/404",
    "name": "page-notfound",
    "parentPage": "",
    "icon":['fas', 'columns'],
    "meta": {
      "layout": {
        "name": "AppLayout_12",
        "slug": "AppLayout_12",
        "content": {
          "column1": [
            {
              "_id": "",
              "name": "PageNotFound404",
              "slug": "PageNotFound404",
              "config": []
            }
          ]
        }
      },
      "title": "Page Not Found",
      "description": "",
      "keywords": "",
      "requireAuth": false,
      "role": "",
      "mainMenu": false,
      "isPublicLayout": false
    }
  }
]

export default routes;
